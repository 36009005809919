import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

export function getControls() {
  return axios.get('/control/list').then(({ data }) => data);
}

export function checkBot() {
  return axios.get('/settings/checkBot').then(({ data }) => data);
}

export function changeParams(id, login, password, mail) {
  return axios.post(`/control/changeParams/?id=${id}`, { login, password, mail }).then((response) => {
    if (response.data.success) {
      openToast('Параметры пользователя успешно сохранены', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteControl(id) {
  return axios.post(`/control/deleteControl/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Пользователь успешно удален', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addControl(login, password, mail) {
  return axios.post('/control/addControl/', { login, password, mail }).then((response) => {
    if (response.data.success) {
      openToast('Пользователь успешно добавлен', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getSubscriptions(id) {
  return axios.get(`/control/subscriptions?id=${id}`).then(({ data }) => data);
}

export function changeSubscriptions(changedSubscr, id) {
  return axios.post('/control/changeSubscriptions/', { changedSubscr, id }).then((response) => {
    if (response.data.success) {
      openToast('Настройки оповещений успешно сохранены', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getStatus(id) {
  return axios.get(`/control/status?id=${id}`).then(({ data }) => data);
}

export function changeAccessSettings(controlId, status, logsToAdd, logsToDelete, compsToAdd, compsToDelete) {
  return axios.post('/control/changeAccess/',
    { controlId, status, logsToAdd, logsToDelete, compsToAdd, compsToDelete }).then((response) => {
    if (response.data.success) {
      openToast('Настройки доступа успешно сохранены', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editTwoFactorAuth(id, useAuth) {
  return axios.post(`/control/editTwoFactorAuth?id=${id}&useAuth=${useAuth}`).then((response) => {
    if (response.data.success) {
      if (useAuth === 1) openToast('Двухфакторная аутентификация для пользователя включена', 'success');
      else openToast('Двухфакторная аутентификация для пользователя выключена', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editTwoFactorAuthForAll(useAuth) {
  return axios.post(`/control/editTwoFactorAuthForAll?useAuth=${useAuth}`).then((response) => {
    if (response.data.success) {
      if (useAuth === 1) openToast('Двухфакторная аутентификация включена', 'success');
      else openToast('Двухфакторная аутентификация выключена', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function disconnectTelegram(id) {
  return axios.post(`/control/disconnectTelegram/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Telegram-аккаунт успешно отвязан', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}
